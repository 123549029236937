import React from "react";
import AdminCard from "./AdminCard";
import { Form, Input, Button } from "antd";
import { StackingPoolIncreaseAllowanceSize } from "../../Helpers/StackingHelper";
import { UpdateConnectLoading } from "../../Redux/Action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { store } from "../../Redux/store";
import { web3_ } from "../../Services";
import { StakingABI } from "../../Config/ABI/StackingABI";
import { StakingContract } from "../../Config/Contract/Contract";
import { type } from "@testing-library/user-event/dist/type";
function AdminCardList(props) {
  return (
    <>
      {AdminFunctionData.map((item, index) => (
        <AdminCard
          data={item}
          key={index}
          funName={item.function}
          address={props.address}
          IDOUpadter={props.IDOUpadter}
          ownerAddress={props.ownerAddress}
          StakingContract={props.StakingContract}
          ICOContract={props.ICOContract}
          ICOTokenAddress={props.ICOTokenAddress}
          spendTokens={props.spendTokens}
        />
      ))}
    </>
  );
}
const AdminFunctionData = [
  {
    name: "Change ICO Time:",
    function: "setIDOTime",
    inputs: [
      {
        placeholder: "StartTime",
        type: "date",
        idName: "_startTime",
      },
      {
        placeholder: "EndTime",
        type: "date",
        idName: "_endTime",
      },
    ],
  },

  {
    name: "Change Presale Rate:",
    function: "setPresaleRate",
    inputs: [
      {
        placeholder: "PresaleRate",
        type: "number",
        idName: "_PresaleRate",
      },
    ],
  },
  {
    name: "Change Soft and Hard Cap:",
    function: "setSoftHardCap",
    inputs: [
      {
        placeholder: "SoftCap",
        type: "number",
        idName: "_softCap",
      },
      {
        placeholder: "HoftCap",
        type: "number",
        idName: "_hardCap",
      },
    ],
  },
  {
    name: "Change Min and Max Amount:",
    function: "setMinMaxBuyRange",
    inputs: [
      {
        placeholder: "Min",
        type: "number",
        idName: "_min",
      },
      {
        placeholder: "Max",
        type: "number",
        idName: "_max",
      },
    ],
  },
  {
    name: "Change token contract address:",
    function: "setTokenContract",
    inputs: [
      {
        placeholder: "Address",
        type: "text",
        idName: "_tokenContract",
      },
    ],
  },
  // {
  //   name: "Retrieve Stucked BEP20 Token",
  //   function: "retrieveStuckedBEP20Token",
  //   inputs: [
  //     { placeholder: "Token Address", type: "text", idName: "_tokenAddr" },
  //     { placeholder: "Amount", type: "number", idName: "_amount" },
  //     { placeholder: "Wallet Address", type: "text", idName: "toWallet" },
  //   ],
  // },
  {
    name: "Full Vesting:",
    function: "fullVesting",
    inputs: [],
  },
  {
    name: "Refund to user:",
    function: "Refund",
    inputs: [],
  },
];
const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
  WalletAddress: state.ConnectWallet.address,
});

export default connect(mapStateToProps, null)(AdminCardList);

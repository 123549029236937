import { Form, Input, Button, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { web3_ } from "../../Services";
import Swal from "sweetalert2";
import { UpdateConnectLoading } from "../../Redux/Action";
import {
  StackingHelperContract,
  StackingRetrieveBEP20TokenStuck,
  StackingSetApyPercent,
  StackingSetMaturityTime,
} from "../../Helpers/StackingHelper";
import { GetBUSDBalanceHelper } from "../../Helpers/BUSDToken";
function StakingAdminFunction(props) {
  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select time!",
      },
    ],
  };
  const [Contract, setContract] = useState(null);
  useEffect(async () => {
    setContract(await StackingHelperContract(props.StakingContract));
  }, []);

  return (
    <div className="max-h-[500px] h-full overflow-y-scroll">
      {/* //Retrive Funciton  */}
      <div class="mb-[30px] " style={{ marginBottom: "30px" }}>
        <h3 class="text-left font-semibold mb-[10px]">
          Retrive stake BEP20 Tokens:
        </h3>
        <Form
          class="text-left"
          id="retrieveStuckedBEP20Token"
          onFinish={(values) => {
            let Amount = values.Amount;
            let TokenAddress = values.TokenAddress;
            let WalletAddress = props.WalletAddress;
            console.log(
              web3_.utils.toWei(Amount, "ether"),
              TokenAddress,
              WalletAddress
            );
            if (props.isConnect) {
              StackingRetrieveBEP20TokenStuck(props.StakingContract, [
                TokenAddress,
                web3_.utils.toWei(Amount, "ether"),
                WalletAddress,
              ])
                .then((newContractInstance) => {
                  UpdateConnectLoading(false);
                  Swal.fire({
                    icon: "success",
                    title: "Transaction Success",
                  });
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                  Swal.fire({
                    icon: "warning",
                    title: "Transaction failed",
                  });
                });
            } else {
              Swal.fire({
                icon: "warning",
                title: "Please connect your wallet",
              });
            }
          }}
        >
          <div class="flex flex-col">
            <Form.Item
              name="TokenAddress"
              rules={[
                {
                  required: true,
                  message: `Please input your TokenAddress!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <Input
                  id="_tokenAddr"
                  placeholder="TokenAddress"
                  className=" w-full bg-[transparent]  outline-0"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="Amount"
              rules={[
                {
                  required: true,
                  message: `Please input your Amount!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="amount"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="Amount"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="WalletAddress"
              rules={[
                {
                  required: true,
                  message: `Please input your WalletAddress!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <Input
                  id="WalletAddress"
                  placeholder="WalletAddress"
                  className=" w-full bg-[transparent]  outline-0"
                />
              </div>
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              class="px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* //Set APY Funciton  */}
      <div class="mb-[30px] " style={{ marginBottom: "30px" }}>
        <h3 class="text-left font-semibold mb-[10px]">Set Apy Percent</h3>
        <Form
          class="text-left"
          id="setApyPercent"
          onFinish={(values) => {
            let Apy_Percent_1 = values.Apy_Percent_1;
            let Apy_Percent_2 = values.Apy_Percent_2;
            let Apy_Percent_3 = values.Apy_Percent_3;
            let Apy_Percent_4 = values.Apy_Percent_4;

            console.log(
              web3_.utils.toWei(Apy_Percent_1, "ether"),
              web3_.utils.toWei(Apy_Percent_2, "ether"),
              web3_.utils.toWei(Apy_Percent_3, "ether"),
              web3_.utils.toWei(Apy_Percent_4, "ether")
            );
            if (props.isConnect) {
              StackingSetApyPercent(props.StakingContract, [
                web3_.utils.toWei(Apy_Percent_1, "ether"),
                web3_.utils.toWei(Apy_Percent_2, "ether"),
                web3_.utils.toWei(Apy_Percent_3, "ether"),
                web3_.utils.toWei(Apy_Percent_4, "ether"),
              ])
                .then((newContractInstance) => {
                  UpdateConnectLoading(false);
                  Swal.fire({
                    icon: "success",
                    title: "Transaction Success",
                  });
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                  Swal.fire({
                    icon: "warning",
                    title: "Transaction failed",
                  });
                });
            } else {
              Swal.fire({
                icon: "warning",
                title: "Please connect your wallet",
              });
            }
          }}
        >
          <div class="flex flex-col">
            <Form.Item
              name="Apy_Percent_1"
              rules={[
                {
                  required: true,
                  message: `Please input your Apy_Percent_1!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_apyPercent_1"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="Apy_Percent_1"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="Apy_Percent_2"
              rules={[
                {
                  required: true,
                  message: `Please input your Apy_Percent_2!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_apyPercent_2"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="Apy_Percent_2"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="Apy_Percent_3"
              rules={[
                {
                  required: true,
                  message: `Please input your Apy_Percent_3!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_apyPercent_3"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="Apy_Percent_3"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="Apy_Percent_4"
              rules={[
                {
                  required: true,
                  message: `Please input your Apy_Percent_4!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_apyPercent_4"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="Apy_Percent_4"
                />
              </div>
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              class="px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>

      {/* //Set Maturatiy Time */}

      <div class="mb-[30px] " style={{ marginBottom: "30px" }}>
        <h3 class="text-left font-semibold mb-[10px]">Set Maturity Time</h3>
        <Form
          class="text-left"
          id="setMaturityTime"
          onFinish={(values) => {
            let maturity_Time_1 = values.maturity_Time_1;
            let maturity_Time_2 = values.maturity_Time_2;
            let maturity_Time_3 = values.maturity_Time_3;
            let maturity_Time_4 = values.maturity_Time_4;

            console.log(
              web3_.utils.toWei(maturity_Time_1, "ether"),
              web3_.utils.toWei(maturity_Time_2, "ether"),
              web3_.utils.toWei(maturity_Time_3, "ether"),
              web3_.utils.toWei(maturity_Time_4, "ether")
            );
            if (props.isConnect) {
              StackingSetMaturityTime(props.StakingContract, [
                web3_.utils.toWei(maturity_Time_1, "ether"),
                web3_.utils.toWei(maturity_Time_2, "ether"),
                web3_.utils.toWei(maturity_Time_3, "ether"),
                web3_.utils.toWei(maturity_Time_4, "ether"),
              ])
                .then((newContractInstance) => {
                  UpdateConnectLoading(false);
                  Swal.fire({
                    icon: "success",
                    title: "Transaction Success",
                  });
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                  Swal.fire({
                    icon: "warning",
                    title: "Transaction failed",
                  });
                });
            } else {
              Swal.fire({
                icon: "warning",
                title: "Please connect your wallet",
              });
            }
          }}
        >
          <div class="flex flex-col">
            <Form.Item
              name="maturity_Time_1"
              rules={[
                {
                  required: true,
                  message: `Please input your maturity_Time_1!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_maturityTime_1"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="maturity_Time_1"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="maturity_Time_2"
              rules={[
                {
                  required: true,
                  message: `Please input your maturity_Time_2!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_maturityTime_2"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="maturity_Time_2"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="maturity_Time_3"
              rules={[
                {
                  required: true,
                  message: `Please input your maturity_Time_3!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_maturityTime_3"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="maturity_Time_3"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="maturity_Time_4"
              rules={[
                {
                  required: true,
                  message: `Please input your maturity_Time_4!`,
                },
              ]}
              style={{ marginBottom: "0" }}
            >
              <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                <InputNumber
                  id="_maturityTime_4"
                  className="w-full bg-[transparent]  outline-0"
                  min="0"
                  step="0.00001"
                  type="number"
                  stringMode
                  placeholder="maturity_Time_4"
                />
              </div>
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              class="px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
const StakeFunctionData = [
  {
    name: "Set Maturity Time",
    function: "setMaturityTime",
    inputs: [
      {
        placeholder: "maturity_Time_1",
        type: "text",
        idName: "_maturityTime_1",
      },
      {
        placeholder: "maturity Time 2",
        type: "text",
        idName: "_maturityTime_2",
      },
      {
        placeholder: "maturity Time 3",
        type: "text",
        idName: "_maturityTime_3",
      },
      {
        placeholder: "maturity Time 4",
        type: "text",
        idName: "_maturityTime_4",
      },
    ],
  },
];
const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
  WalletAddress: state.ConnectWallet.address,
});
export default connect(mapStateToProps, null)(StakingAdminFunction);

import React, { useState, useEffect } from "react";
import { Form, Input, Button, InputNumber, DatePicker } from "antd";
import { type } from "@testing-library/user-event/dist/type";
import { UpdateConnectLoading } from "../../Redux/Action/index";
import {
  ICOHelperContract,
  ICOHelperCurrentTime,
} from "../../Helpers/ICOHelper";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { web3_ } from "../../Services";
import { store } from "../../Redux/store";
import moment from "moment";
import { StackingPoolIncreaseAllowanceSize } from "../../Helpers/StackingHelper";
import { GetBUSDBalanceHelper } from "../../Helpers/BUSDToken";
import { PayoutHelperContract } from "../../Helpers/PayoutTokenHelper";
import { PayoutTokenABI } from "../../Config/ABI/PayoutTokenABI";
const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
  WalletAddress: state.ConnectWallet.address,
});

function AdminCard(props) {
  const [Contract, setContract] = useState(null);
  useEffect(async () => {
    setContract(await ICOHelperContract(props.address));
  }, []);
  const onFinish = async (values, funName) => {
    console.log(funName);
    if (props.isConnect) {
      let InputS = document.forms[funName].getElementsByTagName("input");
      let Array = [];
      for (let i = 0; i < InputS.length; i++) {
        if (document.getElementById(InputS[i].id).type === "number") {
          console.log(document.getElementById(InputS[i].id));
          let value = InputS[i].value;
          console.log(value);
          Array.push(web3_.utils.toWei(value.toString(), "ether"));
        } else {
          Array.push(InputS[i].value);
        }
      }
      console.log(Array);

      let Methods = Contract.methods[funName](...Array);

      if (
        props.ownerAddress.toLowerCase() === props.WalletAddress.toLowerCase()
      ) {
        if (funName === "setIDOTime") {
          let startDate = new Date(Array[0]).getTime() / 1000;
          let endDate = new Date(Array[1]).getTime() / 1000;
          if (startDate >= endDate) {
            Swal.fire(
              "Warning!",
              "Start Date cannot be greater than or equal to end Date",
              "warning"
            );
          } else {
            UpdateConnectLoading(true);
            if (startDate > endDate) {
              UpdateConnectLoading(false);
              Swal.fire(
                "Warning!",
                "Start time cannnot be greater than end time",
                "warning"
              );
            } else {
              Contract.methods[funName](
                web3_.utils.toWei(startDate.toString(), "ether"),
                web3_.utils.toWei(endDate.toString(), "ether")
              )
                .send({
                  from: store.getState().ConnectWallet.address,
                })
                .on("transactionHash", function (transactionHash) {})
                .on("confirmation", () => {})
                // get New Contract Address
                .then(async (newContractInstance) => {
                  props.IDOUpadter();
                  UpdateConnectLoading(false);
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                });
            }
          }
        } else if (funName === "setPresaleRate") {
          //PreSale Rate Validation
          UpdateConnectLoading(true);
          let date = await Contract.methods.getTokenomics().call();
          if (date[3] < parseInt(new Date().getTime() / 1000)) {
            UpdateConnectLoading(false);
            Swal.fire(
              "Warning!",
              "Presale price cannot be change after the start time",
              "warning"
            );
          } else {
            if (parseFloat(Array[0]) > 0) {
              Methods.send({
                from: store.getState().ConnectWallet.address,
              })
                .on("transactionHash", function (transactionHash) {})
                .on("confirmation", () => {})
                // get New Contract Address
                .then((newContractInstance) => {
                  props.IDOUpadter();
                  UpdateConnectLoading(false);
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                });
            } else {
              UpdateConnectLoading(false);
              Swal.fire("Warning!", "Presale price cannot be zero", "warning");
            }
          }
        }
        //set Hard Cap and Soft Cap  and Validation
        else if (funName === "setSoftHardCap") {
          UpdateConnectLoading(true);

          if (
            parseInt(Array[0]) > parseInt(Array[1]) ||
            parseInt(Array[0]) === parseInt(Array[1])
          ) {
            UpdateConnectLoading(false);
            Swal.fire(
              "Warning!",
              "HardCap Must be Greater Than SoftCap and HardCap Value Can not be less than Total Collected BUSD Fund.",
              "warning"
            );
          } else {
            if (parseFloat(Array[0]) > 0 && parseFloat(Array[1]) > 0) {
              Methods.send({ from: store.getState().ConnectWallet.address })
                .on("transactionHash", function (transactionHash) {
                  console.log(transactionHash);
                })
                .on("confirmation", () => {})
                // get New Contract Address
                .then((newContractInstance) => {
                  props.IDOUpadter();
                  UpdateConnectLoading(false);
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                });
            } else {
              UpdateConnectLoading(false);
              Swal.fire(
                "Warning!",
                "HardCap and SoftCap cannot be zero",
                "warning"
              );
            }
          }
        } //set Hard Cap and Soft Cap  and Validation
        else if (funName === "setMinMaxBuyRange") {
          UpdateConnectLoading(true);
          if (parseInt(Array[0]) > parseInt(Array[1])) {
            UpdateConnectLoading(false);
            Swal.fire("Warning!", "Max Must be Greater Than Min.", "warning");
          } else {
            if (parseFloat(Array[0]) > 0 && parseFloat(Array[1]) > 0) {
              Methods.send({ from: store.getState().ConnectWallet.address })
                .on("transactionHash", function (transactionHash) {
                  console.log(transactionHash);
                })
                .on("confirmation", () => {})
                // get New Contract Address
                .then((newContractInstance) => {
                  props.IDOUpadter();
                  UpdateConnectLoading(false);
                })
                .catch((err) => {
                  UpdateConnectLoading(false);
                });
            } else {
              UpdateConnectLoading(false);
              Swal.fire("Warning!", "Max and Min cannot be zero", "warning");
            }
          }
        }
        // set IDO Token Contract Address
        else if (funName === "setTokenContract") {
          if (web3_.utils.isAddress(Array[0])) {
            UpdateConnectLoading(true);
            Methods.send({ from: store.getState().ConnectWallet.address })
              .on("transactionHash", function (transactionHash) {
                console.log(transactionHash);
              })
              .on("confirmation", () => {})
              // get New Contract Address
              .then((newContractInstance) => {
                props.IDOUpadter();
                UpdateConnectLoading(false);
              })
              .catch((err) => {
                UpdateConnectLoading(false);
                console.error(err);
                Swal.fire("Warning!", "Transaction Failed ", "warning");
              });
          } else {
            UpdateConnectLoading(false);
            Swal.fire("Warning!", "Invalid Address", "warning");
          }
        } else if (funName === "vestingByAddress") {
          UpdateConnectLoading(true);
          let isSaleEnded = await Contract.methods.isSaleEnded().call();
          let getClaimableToken = await Contract.methods
            .getClaimableToken(...Array)
            .call();
          if (!isSaleEnded) {
            UpdateConnectLoading(false);
            Swal.fire("Warning!", "Sale is not Ended. Wait for it", "warning");
          } else if (getClaimableToken > 0) {
            UpdateConnectLoading(false);
            Swal.fire(
              "Warning!",
              "User does not have amount to calim.",
              "warning"
            );
          } else {
            Methods.send({
              from: store.getState().ConnectWallet.address,
            })
              .on("transactionHash", function (transactionHash) {
                console.log(transactionHash);
              })
              .on("confirmation", () => {})
              // get New Contract Address
              .then((newContractInstance) => {
                UpdateConnectLoading(false);
              })
              .catch((err) => {
                UpdateConnectLoading(false);
              });
          }
        } else if (funName === "retrieveStuckedBEP20Token") {
          UpdateConnectLoading(true);
          let isSaleEnded = await Contract.methods.isSaleEnded().call();
          let getClaimableToken = await GetBUSDBalanceHelper(props.address);

          if (!isSaleEnded) {
            UpdateConnectLoading(false);
            Swal.fire("Warning!", "Sale is not Ended. Wait for it", "warning");
          } else if (parseFloat(getClaimableToken) / Math.pow(10, 18) <= 0) {
            UpdateConnectLoading(false);
            Swal.fire(
              "Warning!",
              "User does not have amount to calim.",
              "warning"
            );
          } else {
            Methods.send({
              from: store.getState().ConnectWallet.address,
            })
              .on("transactionHash", function (transactionHash) {
                console.log(transactionHash);
              })
              .on("confirmation", () => {})
              // get New Contract Address
              .then((newContractInstance) => {
                UpdateConnectLoading(false);
              })
              .catch((err) => {
                UpdateConnectLoading(false);
              });
          }
        } else if (funName === "fullVesting") {
          let isSaleEnded = await Contract.methods.isSaleEnded().call();

          try {
            if (!isSaleEnded) {
              UpdateConnectLoading(false);
              Swal.fire(
                "Warning!",
                "Sale is not Ended. Wait for it",
                "warning"
              );
            } else {
              if (
                props.ICOTokenAddress !==
                "0x0000000000000000000000000000000000000000"
              ) {
                let BalanceOf = await new web3_.eth.Contract(
                  PayoutTokenABI,
                  props.ICOTokenAddress
                ).methods
                  .balanceOf(props.address)
                  .call();
                // let InvesteAmmount = await Contract.methods
                //   .totalIdoTokenNeedForInvestor()
                //   .call();

                if (
                  parseFloat(props.spendTokens).toFixed(3) / Math.pow(10, 18) <=
                  parseFloat(BalanceOf).toFixed(3) / Math.pow(10, 18)
                ) {
                  Methods.send({
                    from: store.getState().ConnectWallet.address,
                  })
                    .on("transactionHash", function (transactionHash) {
                      console.log(transactionHash);
                    })
                    .on("confirmation", () => {})
                    // get New Contract Address
                    .then((newContractInstance) => {
                      props.IDOUpadter();
                      UpdateConnectLoading(false);
                    })
                    .catch((err) => {
                      UpdateConnectLoading(false);
                    });
                } else {
                  UpdateConnectLoading(false);
                  Swal.fire(
                    "Warning!",
                    "You dont have enough token for distribute ",
                    "warning"
                  );
                }
              } else {
                UpdateConnectLoading(false);
                Swal.fire("Warning!", "You don't set token address", "warning");
              }
            }
          } catch (err) {
            Swal.fire("Warning!", "Something went wrong", "warning");
          }
        } else if (funName === "Refund") {
          let isSaleEnded = await Contract.methods.isSaleEnded().call();
          let isSoftCapReach = await Contract.methods.isSoftCapReach().call();
          let SellTrackDataset = await Contract.methods
            .SellTrackDataset(props.WalletAddress)
            .call();

          if (isSaleEnded) {
            if (!isSoftCapReach) {
              if (!SellTrackDataset.isRefundReceived) {
                Methods.send({
                  from: store.getState().ConnectWallet.address,
                })
                  .on("transactionHash", function (transactionHash) {
                    console.log(transactionHash);
                  })
                  .on("confirmation", () => {})
                  // get New Contract Address
                  .then((newContractInstance) => {
                    props.IDOUpadter();
                    UpdateConnectLoading(false);
                  })
                  .catch((err) => {
                    UpdateConnectLoading(false);
                  });
              } else {
                UpdateConnectLoading(false);
                Swal.fire("Warning!", "Already Got thier Tokens", "warning");
              }
            } else {
              UpdateConnectLoading(false);
              Swal.fire("Warning!", " Soft cap is reached", "warning");
            }
          } else {
            UpdateConnectLoading(false);
            Swal.fire("Warning!", "Sale is not Ended ", "warning");
          }
        }
        // else if (funName === "setIDOStatus") {
        //   UpdateConnectLoading(true);
        //   let isSaleEnded = await Contract.methods.isSaleEnded().call();
        //   console.log(isSaleEnded);

        //   Methods.send({
        //     from: store.getState().ConnectWallet.address,
        //   })
        //     .on("transactionHash", function (transactionHash) {
        //       console.log(transactionHash);
        //     })
        //     .on("confirmation", () => {})
        //     // get New Contract Address
        //     .then((newContractInstance) => {
        //       props.IDOUpadter();
        //       UpdateConnectLoading(false);
        //     })
        //     .catch((err) => {
        //       UpdateConnectLoading(false);
        //     });
        // }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Your are not owner of ICO ",
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please connect your wallet",
      });
    }
  };
  const config = {
    rules: [
      {
        type: "object",
        required: true,
        message: "Please select time!",
      },
    ],
  };
  return (
    <div class="mb-[30px] " style={{ marginBottom: "30px" }}>
      <h3 class="text-left font-semibold mb-[10px]">{props.data.name}</h3>
      <Form
        class="text-left"
        id={props.funName}
        onFinish={(values) => onFinish(values, props.funName)}
      >
        <div class="flex flex-col">
          {props.data.inputs.map((item, index) => {
            if (item.type === "date") {
              return (
                <div
                  key={index}
                  class=" rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]"
                >
                  <Form.Item
                    name={item.placeholder}
                    {...config}
                    style={{ marginBottom: "0" }}
                    key={index}
                  >
                    <DatePicker
                      placeholder={item.placeholder}
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      className="w-full hover:outline-none"
                      id={item.idName}
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return (
                          current && current < moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                    />
                  </Form.Item>
                </div>
              );
            } else {
              return (
                <Form.Item
                  key={index}
                  name={item.placeholder}
                  rules={[
                    {
                      required: true,
                      message: `Please input your ${item.placeholder}!`,
                    },
                  ]}
                  style={{ marginBottom: "0" }}
                >
                  <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                    {/* <input
                  placeholder={item.placeholder}
                  class=" w-full bg-[transparent]  outline-0"
                  required
                  type={item.type}
                ></input> */}
                    {item.type === "text" && (
                      <Input
                        id={item.idName}
                        placeholder={item.placeholder}
                        className=" w-full bg-[transparent]  outline-0"
                      />
                    )}
                    {item.type === "number" && (
                      <InputNumber
                        id={item.idName}
                        className="w-full bg-[transparent]  outline-0"
                        min="0"
                        step="0.00001"
                        type="number"
                        stringMode
                        placeholder={item.placeholder}
                      />
                    )}
                  </div>
                </Form.Item>
              );
            }
          })}
        </div>
        {/* <button
          type="submit"
          class="px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
        >
          Submit
        </button> */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            class="px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default connect(mapStateToProps, null)(AdminCard);

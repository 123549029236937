import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import Dashboard from "../pages/Dashboard";
import AuthenticationPage from "../pages/AuthenticationPage";
import IDOPage from "../pages/IDOPage";
import PrivateRoute from "./PrivateRoute";
import CreatePage from "../pages/CreatePage";
import { store } from "../Redux/store";
import UserIdoPage from "../pages/UserIdoPage";
import CreateToken from "../pages/CreateToken";
function RouteList() {
  console.log(store.getState());
  return (
    <Routes>
      <Route index path="/auth" element={<AuthenticationPage />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/create" element={<CreatePage />} />
        <Route path="/token" element={<CreateToken />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/ido/:address" element={<IDOPage />} />
        <Route path="/user-list" element={<UserIdoPage />} />
      </Route>
    </Routes>
  );
}

export default RouteList;

import React, { useEffect, useState } from "react";
import Card from "../Components/Card/Card";
import { BiSearch } from "react-icons/bi";
import { BUSDHelperContract, GetBUSDBalanceHelper } from "../Helpers/BUSDToken";
import {
  FactoryGetAllIntractionDetails,
  FactoryGetLastIDOStatusEnded,
} from "../Helpers/FactoryHelper";
import { UpdateConnectLoading } from "../Redux/Action";
import Loading from "../Components/Loading/Loading";
import Search from "../Components/Serach/Search";
function Dashboard() {
  const [ICODetails, setICODetails] = useState([]);
  const [ICODetailsFilter, setICODetailsFilter] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [lengthICO, setlengthICO] = useState(0);
  useEffect(async () => {
    // Get all intraction details from factory contract
    setisLoading(true);
    FactoryGetAllIntractionDetails()
      .then((res) => {
        setlengthICO(res.length);
        setICODetails(res);
        // console.log(res);
        setICODetailsFilter(res);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });
  }, []);
  if (ICODetails.length === 0) {
    UpdateConnectLoading(true);
  } else {
    UpdateConnectLoading(false);
  }
  return (
    <>
      <div className="pb-[40px] bg-[#000]">
        <div className="h-screen p-[24px] overflow-scroll">
          <div className="flex justify-between md:px-0 lg:px-[120px] py-[48px] items-center flex-wrap">
            <div className="text-[32px] font-bold text-[#fff]">
              <div>ICO Collection</div>
            </div>
            <div className="flex gap-x-[8px]">
              <div className="hidden md:flex rounded-md p-[8px] border ease-in duration-300 border-[#e8ecf4] hover:ease-out hover:border-[#d9b865]">
                <div className="flex items-center mr-2 text-[#fff]">
                  <BiSearch />
                </div>
                {/* <input
                  placeholder="Search..."
                  className=" w-full bg-[transparent]  outline-0"
                ></input> */}
                <Search
                  filterData={ICODetails}
                  setICODetailsFilter={setICODetailsFilter}
                  placeholder="Search..."
                  className=" w-full bg-[transparent]  outline-0 text-white"
                />
              </div>
              {/* <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27]">
                <div>
                  <input
                    placeholder="Sort By"
                    className=" w-full bg-[transparent]  outline-0"
                  ></input>
                </div>
              </div> */}
            </div>
          </div>

          <div className=" block md:hidden flex rounded-md p-[8px] border border-[#e8ecf4] bg-[#f7f9fd]">
            <div className="flex items-center mr-2 text-[#9ca3af]">
              <BiSearch />
            </div>

            <Search
              filterData={ICODetails}
              setICODetailsFilter={setICODetailsFilter}
              placeholder="Search..."
              className=" w-full bg-[transparent]  outline-0"
            />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {lengthICO > 0 ? (
                <div className="max-w-[300px] lg:ml-[120px] my-[48px]">
                  {/* <h3 className="text-left text-[22px] font-bold text-[#fff]">
                    Active ICO
                  </h3> */}
                  <Card data={ICODetails[lengthICO - 1]} key={1} />
                </div>
              ) : (
                <h3 className="text-left  lg:px-[120px] text-[22px] font-bold text-[#fff]">
                  {/* There is no active ICO */}
                </h3>
              )}
              <h3 className="text-left  lg:px-[120px] text-[22px] font-bold text-[#fff]">
                {/* {lengthICO - 1 >= 1 ? "Previous ICOs" : "Previous ICO"} */}
              </h3>
              <div
                className="grid lg:px-[120px] py-[48px] gap-[24px]"
                style={{
                  gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
                }}
              >
                {lengthICO > 0
                  ? ICODetailsFilter.map((data, item) => (
                      <>
                        {" "}
                        {lengthICO === item + 1 ? null : (
                          <Card data={data} key={item} />
                        )}
                      </>
                    ))
                  : ICODetailsFilter.map((data, item) => (
                      <Card data={data} key={item} />
                    ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;

import React, { useEffect, useState } from "react";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { IoMdPricetag, IoIosArrowDown } from "react-icons/io";
import { useParams } from "react-router-dom";
import AdminCard from "../Components/AdminFunction/AdminCard";
import {
  ICOHelperContract,
  ICOHelperPayoutContractTokenSymbol,
  ICOHelperUpdateSocialMedia,
} from "../Helpers/ICOHelper";
import { store } from "../Redux/store";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { UpdateConnectLoading, UpdateConnectModal } from "../Redux/Action";
import { DivideBy18 } from "../Helpers/utils";
import { VscGlobe } from "react-icons/vsc";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import moment from "moment";
import Loading from "../Components/Loading/Loading";
import AdminCardList from "../Components/AdminFunction/AdminCardList";
import { Button, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CreateNewStaking } from "../Helpers/StackingHelper";
import StakingAdminFunction from "../Components/AdminFunction/StakingAdminFunction";

const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
  isLoadingEnable: state.UtilsReducers.isLoadingEnable,
  WalletAddress: state.ConnectWallet.address,
});
function IDOPage(props) {
  const { address } = useParams();
  const [ICOContract, setICOContract] = useState(null);
  const [investeUser, setInvesteUser] = useState(null);
  const [payoutContractSymbol, setPayoutContractAddress] = useState("BUSD");
  const [ICOTokenContractSymbol, setICOTokenContractSymbol] = useState("PTT");
  const [ICOData, setICOData] = useState({
    PresaleRate: 0,
    softCap: 0,
    hardCap: 0,
    startTime: 0,
    endTime: 0,
    rasiedAmount: 0,
    isSaleEnded: false,
    ownerAddress: "",
    minimumInvestment: 0,
    maximumInvestment: 0,
    payoutContractAddress: "",
    stakingContractAddress: "",
    idoTokenContractAddress: "",
    idoTokenContractSymbol: "BUSD",
    investeUserAddress: [],
    isStakeEnable: false,
    tokenName: "Name",
    tokenSymbol: "NAM",
    description: "",
    fbLink: "",
    twLink: "",
    weblink: "",
    iconLink: "",
  });

  useEffect(async () => {
    if (props.isConnect) {
      console.log(address);
      let ICOcontract = await ICOHelperContract(address);
      console.log(ICOcontract);
      setICOContract(ICOcontract);
      UpdateConnectLoading(true);
      // Get Basic Detils of IDO
      await ICOcontract.methods
        .getSocialMediaData()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            tokenName: result[0],
            tokenSymbol: result[1],
            iconLink: result[2],
            fbLink: result[3],
            twLink: result[4],
            weblink: result[5],
            description: result[6],
          }));
        });
      await ICOcontract.methods
        .showAllTrade()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            investeUserAddress: result[0],
          }));
          setInvesteUser(result[1]);
        });

      await ICOcontract.methods
        .getStakingData()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            isStakeEnable: result[0],
            stakingContractAddress: result[1],
          }));
        });
      await ICOcontract.methods
        .owner()
        .call()
        .then((result) => {
          console.log(result);
          setICOData((value) => ({
            ...value,
            ownerAddress: result,
          }));
        });
      await ICOcontract.methods
        .getTokenomics()
        .call()
        .then(async (result) => {
          UpdateConnectLoading(false);
          setPayoutContractAddress(
            await ICOHelperPayoutContractTokenSymbol(result[10])
          );
          setICOTokenContractSymbol(
            await ICOHelperPayoutContractTokenSymbol(result[11])
          );

          setICOData((value) => ({
            ...value,
            PresaleRate: result[0],
            softCap: result[1],
            hardCap: result[2],
            startTime: result[3],
            endTime: result[4],
            rasiedAmount: result[8],
            spendTokens: result[7],
            minimumInvestment: result[5],
            maximumInvestment: result[6],
            payoutContractAddress: result[10],
            idoTokenContractAddress: result[11],
          }));
        });

      // Get Basic Detils of IDO
      // await ICOcontract.methods
      //   .collectedFund()
      //   .call()
      //   .then((result) => {
      //     setICOData((value) => ({
      //       ...value,
      //       rasiedAmount: result,
      //     }));
      //   });

      await ICOcontract.methods
        .isSaleEnded()
        .call()
        .then((result) =>
          setICOData((value) => ({
            ...value,
            isSaleEnded: result,
          }))
        );
    } else {
      UpdateConnectLoading(false);
    }
  }, [props.isConnect]);

  console.log(ICOData);
  async function IDOUpadter() {
    await ICOContract.methods
      .getTokenomics()
      .call()
      .then((result) => {
        console.log(result);
        UpdateConnectLoading(false);
        setICOData((value) => ({
          ...value,
          PresaleRate: result[0],
          softCap: result[1],
          hardCap: result[2],
          startTime: result[3],
          endTime: result[4],
          rasiedAmount: result[8],
          spendTokens: result[7],
          minimumInvestment: result[5],
          maximumInvestment: result[6],
          payoutContractAddress: result[10],
          idoTokenContractAddress: result[11],
        }));
      });

    await ICOContract.methods
      .owner()
      .call()
      .then((result) => {
        console.log(result);
        setICOData((value) => ({
          ...value,
          ownerAddress: result,
        }));
      });
  }
  async function onFinish(values) {
    console.log(values);

    let iconURL = values.IconUrl;
    let Facebook = values.FacebookUrl;
    let Twitter = values.TwitterUrl;
    let Website = values.Website;
    let Description = values.Description;

    if (values.IconUrl === undefined) {
      iconURL = "";
    }
    if (values.FacebookUrl === undefined) {
      Facebook = "";
    }
    if (values.TwitterUrl === undefined) {
      Twitter = "";
    }
    if (values.Website === undefined) {
      Website = "";
    }
    if (values.Description === undefined) {
      Description = "";
    }

    let Array = [iconURL, Facebook, Twitter, Website, Description];
    if (props.isConnect) {
      if (
        ICOData.ownerAddress.toLowerCase() === props.WalletAddress.toLowerCase()
      ) {
        await ICOHelperUpdateSocialMedia(address, Array).then((result) => {
          UpdateConnectLoading(false);
        });
      } else {
        Swal.fire("warning", "You are not owner");
      }
    } else {
      Swal.fire("warning", "Please connect to Wallet");
    }
  }
  console.log(store.getState().ConnectWallet.isConnect);
  if (props.isLoadingEnable) {
    return (
      <>
        <Loading />
      </>
    );
  }
  const deployStackingContract = async () => {
    await CreateNewStaking(ICOData.idoTokenContractAddress, address)
      .send({
        from: props.WalletAddress,
      })
      .on("transactionHash", function (transactionHash) {
        console.log(transactionHash);
      })
      .on("confirmation", () => {})
      // get New Contract Address
      .then(async (newContractInstance) => {
        await ICOContract.methods
          .getStakingData()
          .call()
          .then((result) => {
            console.log(result);
            setICOData((value) => ({
              ...value,
              isStakeEnable: result[0],
              stakingContractAddress: result[1],
            }));
          });
      })
      .catch((err) => {
        // setisTransaction(false);
      });
  };

  return (
    <main className="relative bg-black">
      <img
        src={process.env.PUBLIC_URL + "/assets/banner.svg"}
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: "100%",
        }}
      ></img>
      <div>
        <div className="mt-0 py-[50px] relative z-10 max-w-[1000px] mx-auto pb-[120px]">
          <div className="flex flex-col md:flex-row gap-[24px]">
            <div className="flex-1">
              {/* <!-- IDO details --> */}
              <div className="mb-[23px] max-w-[800px] mx-auto min-h-[400px] bg-white  border border-[#e8ecf4] rounded-lg  px-[40px] drop-shadow-xl ">
                <div className="py-[24px]">
                  <div className="flex items-center gap-5">
                    <div className="rounded-full w-[60px] p-1 bg-[#000] border border-black  h-[60px]  flex items-center">
                      <img
                        className="rounded-full mr-[8px] "
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "https://psalam-ido-launchpad.vercel.app/assets/logo.png";
                        }}
                        src={ICOData.iconLink}
                      ></img>
                    </div>
                    <div className="my-[16px] tracking-tight text-[34px] text-black  font-bold">
                      {ICOData.tokenName}
                    </div>
                  </div>
                  <div>
                    <p className="text-left text-black font-bold">
                      {ICOData.description}
                    </p>
                  </div>
                  <div className="flex flex-wrap gap-3 text-left text-black font-bold">
                    {/* {ICOData.weblink === " " ? ( */}
                    <div className="text-left py-[16px] flex items-center">
                      {ICOData.weblink !== "" && (
                        <a
                          className=" text-black-500 px-[10px]"
                          href={ICOData.weblink}
                          target="_blank"
                        >
                          <span className="flex">
                            <VscGlobe size={20} color="#c4922f" />
                          </span>
                        </a>
                      )}
                    </div>
                    {/* ) : null} */}
                    {/* {ICOData.fbLink === " " ? ( */}
                    <div className="text-left py-[16px] flex items-center">
                      {ICOData.fbLink !== "" && (
                        <a
                          className="  px-[10px]"
                          href={ICOData.fbLink}
                          target="_blank"
                        >
                          <span className="flex">
                            <BsFacebook size={20} color="#c4922f" />
                          </span>
                        </a>
                      )}
                    </div>
                    {/* ) : null} */}

                    {/* {ICOData.twLink === " " ? ( */}
                    <div className="text-left py-[16px] flex items-center">
                      {ICOData.twLink !== "" && (
                        <a
                          className=" text-blue-500 px-[10px]"
                          href={ICOData.twLink}
                          target="_blank"
                        >
                          {" "}
                          <span className="flex">
                            <BsTwitter size={20} color="#c4922f" />
                          </span>
                        </a>
                      )}
                    </div>
                    {/* ) : null} */}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Presale Rate</div>
                  <div>
                    {DivideBy18(ICOData.PresaleRate)} {ICOTokenContractSymbol}/
                    {payoutContractSymbol}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Hard Cap</div>
                  <div>
                    {DivideBy18(ICOData.hardCap)} {payoutContractSymbol}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Soft Cap</div>
                  <div>
                    {DivideBy18(ICOData.softCap)} {payoutContractSymbol}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Min Investment</div>
                  <div>
                    {DivideBy18(ICOData.minimumInvestment)}{" "}
                    {payoutContractSymbol}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Max Investment</div>
                  <div>
                    {DivideBy18(ICOData.maximumInvestment)}{" "}
                    {payoutContractSymbol}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Raised Amount</div>
                  <div>
                    {DivideBy18(ICOData.rasiedAmount)} {payoutContractSymbol}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Start Date</div>
                  {console.log()}
                  <div>
                    {" "}
                    {moment(parseInt(ICOData.startTime * 1000)).format(
                      "MMM Do YYYY"
                    )}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">End Date</div>
                  <div>
                    {" "}
                    {moment(parseInt(ICOData.endTime * 1000)).format(
                      "MMM Do YYYY"
                    )}
                  </div>
                </div>
                <div className="py-[24px] flex justify-between border-b border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Stake Enable</div>
                  <div> {ICOData.isStakeEnable ? "True" : "False"}</div>
                </div>
                <div className="py-[24px] flex justify-between border-b items-center border-[#e8ecf4]">
                  <div className="text-[#7c859a]">Progress</div>
                  <div className="w-[50%] h-full">
                    <div
                      className={`rounded-full h-[12px] bg-gradient-to-r from-[#f4e9b0] to-[#cca143] w-[${
                        (ICOData.rasiedAmount / ICOData.hardCap) * 100
                      }%] `}
                    ></div>
                    <div className="flex text-[#515152] justify-between">
                      <span>0%</span>
                      <span>
                        {parseInt(
                          (ICOData.rasiedAmount / ICOData.hardCap) * 100
                        )}{" "}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Deploy Stack */}
              {ICOData.isStakeEnable && (
                <div className="max-w-[800px] mx-auto border border-[#e8ecf4] rounded-lg rounded-tr-lg bg-white mb-5">
                  <div class="flex flex-col px-[16px] py-[8px]">
                    <h1 className="font-bold text-xl mb-[30px] px-[16px] py-[8px]">
                      Staking Information
                    </h1>
                    <StakingAdminFunction
                      StakingContract={ICOData.stakingContractAddress}
                    />
                    {ICOData.isStakeEnable &&
                    (ICOData.stakingContractAddress === "" ||
                      ICOData.stakingContractAddress ===
                        "0x0000000000000000000000000000000000000000") ? (
                      <div>
                        <div>Deploy Staking Contract</div>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={deployStackingContract}
                        >
                          Deploy
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="max-w-[800px] mx-auto border border-[#e8ecf4] rounded-lg rounded-tr-lg bg-white">
                <div class="flex flex-col px-[16px] py-[8px]">
                  <h1 className="font-bold text-xl mb-[30px] px-[16px] py-[8px]">
                    Update Information
                  </h1>
                  <Form
                    class="text-left"
                    // id={props.funName}
                    onFinish={(values) => onFinish(values)}
                  >
                    <Form.Item name={"IconUrl"} style={{ marginBottom: "0" }}>
                      <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                        <Input
                          placeholder="Icon Url"
                          className=" w-full bg-[transparent]  outline-0"
                        />
                      </div>
                    </Form.Item>

                    <Form.Item
                      name={"FacebookUrl"}
                      style={{ marginBottom: "0" }}
                    >
                      <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                        <Input
                          placeholder="Facebook Url"
                          className=" w-full bg-[transparent]  outline-0"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      name={"TwitterUrl"}
                      style={{ marginBottom: "0" }}
                    >
                      <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                        <Input
                          placeholder="Twitter Url"
                          className=" w-full bg-[transparent]  outline-0"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item name={"Website"} style={{ marginBottom: "0" }}>
                      <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                        <Input
                          placeholder="Webiste Url"
                          className=" w-full bg-[transparent]  outline-0"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item
                      name={"Description"}
                      style={{ marginBottom: "0" }}
                    >
                      <div class="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  cursor-pointer hover:ease-out hover:border-[#060b27] mb-[12px]">
                        <TextArea
                          placeholder="Description"
                          className=" w-full bg-[transparent]  outline-0"
                        />
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        class="px-[16px] py-[8px] bg-[#060b27] rounded-md text-white cursor-pointer ease-in duration-300  hover:shadow-xl hover:shadow-[#060b27]/20"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
              <div className="bg-white mt-4 max-w-[800px] mx-auto border border-[#e8ecf4] rounded-lg rounded-tr-lg">
                <div class="flex flex-col px-[16px] py-[8px]">
                  <h1 className="font-bold text-xl mb-[30px] px-[16px] py-[8px]">
                    Client Investement
                  </h1>

                  {investeUser !== null ? (
                    ICOData.investeUserAddress.map((item, key) => (
                      <div
                        key={key}
                        className="text-[12px] md:text-base py-[24px] flex justify-between border-b border-[#e8ecf4]"
                      >
                        {console.log(investeUser)}
                        <div className=" text-[#1890ff]">{item}</div>
                        <div>{DivideBy18(investeUser[key])} BUSD</div>
                      </div>
                    ))
                  ) : (
                    <h3 className="font-semibold   mb-[30px] px-[16px] py-[8px]">
                      Don't have investeUser
                    </h3>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full md:max-w-[400px] bg-white border border-[#e8ecf4] rounded-lg  p-[40px] drop-shadow-xl">
              <h1 className="font-bold text-xl mb-[30px]">Admin Functions</h1>
              <div className="flex flex-col  overflow-y-scroll">
                <AdminCardList
                  address={address}
                  IDOUpadter={IDOUpadter}
                  ownerAddress={ICOData.ownerAddress}
                  isSaleEnded={ICOData.isSaleEnded}
                  StakingContract={ICOData.stakingContractAddress}
                  ICOContract={ICOData.contractAddress}
                  ICOTokenAddress={ICOData.idoTokenContractAddress}
                  spendTokens={ICOData.spendTokens}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const IDODataInformation = [
  { title: "Invested User", icon: <GiReceiveMoney /> },
  { title: "User Allownce", icon: <GiTakeMyMoney /> },
];
export default connect(mapStateToProps, null)(IDOPage);

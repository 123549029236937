import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, DatePicker } from "antd";
import { CreateNewToken } from "../Helpers/TokenCreationHelper";
import moment, { max } from "moment";
import { web3_ } from "../Services";
import Swal from "sweetalert2";
import { UpdateConnectLoading, UpdateConnectModal } from "../Redux/Action";
import { connect } from "react-redux";
import { store } from "../Redux/store";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading/Loading";
import { FactoryGetLastIDOStatusEnded } from "../Helpers/FactoryHelper";
const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
});
const FromCreateToken = (props) => {
  const navigate = useNavigate();
  const [isTransaction, setisTransaction] = useState(false);
  useEffect(async () => {
    console.log();
  });
  const onFinish = async (values) => {
    if (!props.isConnect) {
      Swal.fire({
        icon: "warning",
        title: "Please connect your wallet",
      }).then(() => {
        UpdateConnectModal(true);
      });
    } else {
      if (
        values.Name.trim() === "" ||
        values.Decimal.trim() === "" ||
        values.Symbol.trim() === "" ||
        values.Supply.trim() === ""
      ) {
        Swal.fire("Warning", "Enter correct Value", "warning");
      } else {
        setisTransaction(true);
        CreateNewToken([
          values.Name,
          values.Symbol,
          values.Supply,
          values.Decimal,
        ])
          .send({
            from: store.getState().ConnectWallet.address,
          })
          .on("transactionHash", function (transactionHash) {
            console.log(transactionHash);
          })
          .on("confirmation", () => {})
          // get New Contract Address
          .then((newContractInstance) => {
            setisTransaction(false);
            navigate("/dashboard", { replace: true });
          })
          .catch((err) => {
            setisTransaction(false);
          });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    setisTransaction(false);
    console.log("Failed:", errorInfo);
  };

  return isTransaction ? (
    <>
      <div className="h-screen w-full bg-white ">
        <Loading />
      </div>
    </>
  ) : (
    <>
      {" "}
      <Form
        name="time_related_controls"
        initialValues={{
          remember: true,
        }}
        layout={"vertical"}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {InputData.map((item, key) => (
          <Form.Item
            label={item.title}
            name={item.placeholder}
            key={key}
            rules={[
              {
                required: true,
                message: `Please input your ${item.placeholder}!`,
              },
            ]}
          >
            <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
              <div className="w-full border-none text-left">
                {item.type === "text" ? (
                  <Input className=" w-full bg-[transparent]  outline-0 text-white" />
                ) : (
                  <InputNumber
                    className=" w-full bg-[transparent]  outline-0"
                    min="0"
                    max={item.max}
                    id={item.id}
                  />
                )}
              </div>
            </div>
          </Form.Item>
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
const InputData = [
  {
    title: "Token Name",
    placeholder: "Name",
    type: "text",
  },
  {
    title: "Token Symbol",
    placeholder: "Symbol",
    type: "text",
  },

  {
    title: "Decimal",
    placeholder: "Decimal",
    type: "number",
    max: 18,
  },
  {
    title: "Supply",
    placeholder: "Supply",
    type: "number",
    id: "supply",
  },
];

export default connect(mapStateToProps, null)(FromCreateToken);

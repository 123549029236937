import React from "react";
import { DatePicker, Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import FormCreatePage from "../Components/FormCreatePage";
import { connect } from "react-redux";
import Loading from "../Components/Loading/Loading";
const { RangePicker } = DatePicker;

function CreatePage(props) {
  function onChange(value, dateString) {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  }

  return (
    <>
      {/* <div className="hidden md:block absolute top-0 left-0 right-0 bottom-0"></div> */}
      <main className=" h-full bg-[#000] relative  py-0 md:py-[50px]">
        <img
          src={process.env.PUBLIC_URL + "assets/banner.svg"}
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: "100%",
          }}
        ></img>
        <div
          style={{ border: "4px solid #f4e9b0" }}
          className="max-w-[800px] mx-auto  relative bg-[#fff] border border-[#e8ecf4] rounded-lg  p-[40px] drop-shadow-xl"
        >
          <h1 className="text-3xl font-bold text-black">Create ICO</h1>
          <FormCreatePage />
        </div>
      </main>
    </>
  );
}

export default CreatePage;

import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, DatePicker, Checkbox } from "antd";
import { CreateNewICO } from "../Helpers/ICOHelper";
import moment from "moment";
import { web3_ } from "../Services";
import Swal from "sweetalert2";
import { UpdateConnectLoading, UpdateConnectModal } from "../Redux/Action";
import { connect } from "react-redux";
import { store } from "../Redux/store";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading/Loading";
import { FactoryGetLastIDOStatusEnded } from "../Helpers/FactoryHelper";
const config = {
  rules: [
    {
      type: "object",
      required: true,
      message: "Please select time!",
    },
  ],
};

const mapStateToProps = (state) => ({
  isConnect: state.ConnectWallet.isConnect,
});
const FormCreatePage = (props) => {
  const navigate = useNavigate();
  const [isTransaction, setisTransaction] = useState(false);

  const onFinish = async (values) => {
    console.log(props.isConnect, "dsjdfghj");
    if (!props.isConnect) {
      Swal.fire({
        icon: "warning",
        title: "Please connect your wallet",
      }).then(() => {
        UpdateConnectModal(true);
      });
    } else {
      // let isSaleEnded = await FactoryGetLastIDOStatusEnded();
      if (true) {
        let rate = web3_.utils.toWei(values.Rate.toString(), "ether");
        let softCap = web3_.utils.toWei(values.Soft_Cap.toString(), "ether");
        let hardCap = web3_.utils.toWei(values.Hard_Cap.toString(), "ether");
        let minInvest = web3_.utils.toWei(values.MinInvest.toString(), "ether");
        let maxInvest = web3_.utils.toWei(values.MaxInvest.toString(), "ether");
        let startDate = parseInt(new Date(values.startDate).getTime() / 1000);
        let endDate = parseInt(new Date(values.endDate).getTime() / 1000);
        let isStake = values.isStack ? true : false;
        let idoTokenContractAddress =
          values.idoTokenContractAddress === undefined
            ? ""
            : values.idoTokenContractAddress;
        let payoutTokenContractAddress =
          values.payoutTokenContractAddress === undefined
            ? ""
            : values.payoutTokenContractAddress;
        console.log(isStake);
        let TwitterUrl =
          values.TwitterUrl === undefined ? "" : values.TwitterUrl;
        let WebsiteUrl =
          values.WebsiteUrl === undefined ? "" : values.WebsiteUrl;
        let FacebookUrl =
          values.FacebookUrl === undefined ? "" : values.FacebookUrl;
        console.log(
          softCap,
          hardCap,
          rate,
          startDate,
          endDate,
          minInvest,
          maxInvest
        );
        if (
          softCap < hardCap &&
          softCap > 0 &&
          hardCap > 0 &&
          rate > 0 &&
          startDate < endDate
        ) {
          setisTransaction(true);
          CreateNewICO(
            [
              values.Name,
              values.Symbol,
              values.IconUrl,

              FacebookUrl,
              TwitterUrl,
              WebsiteUrl,
              values.Description,
            ],
            [
              rate,
              softCap,
              hardCap,
              web3_.utils.toWei(startDate.toString(), "ether"),
              web3_.utils.toWei(endDate.toString(), "ether"),
              minInvest,
              maxInvest,
            ],
            isStake,
            idoTokenContractAddress,
            payoutTokenContractAddress
          )
            .send({
              from: store.getState().ConnectWallet.address,
            })
            .on("transactionHash", function (transactionHash) {
              console.log(transactionHash);
            })
            .on("confirmation", () => {})
            // get New Contract Address
            .then((newContractInstance) => {
              setisTransaction(false);
              navigate("/dashboard", { replace: true });
            })
            .catch((err) => {
              setisTransaction(false);
            });
        } else {
          if (startDate >= endDate) {
            setisTransaction(false);
            Swal.fire(
              "Warning!",
              "Start Date cannot be greater than or equal to end Date",
              "warning"
            );
          }
          if (rate <= 0) {
            setisTransaction(false);
            Swal.fire({
              icon: "warning",
              title: "Enter correct amount of rate.",
            });
          }

          if (softCap <= 0) {
            setisTransaction(false);
            Swal.fire({
              icon: "warning",
              title: "Sortcap value is not correct.",
            });
          }
          if (hardCap <= 0) {
            setisTransaction(false);
            Swal.fire({
              icon: "warning",
              title: "HardCap value is not correct.",
            });
          }
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Previous IDO isn't over yet",
        });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    setisTransaction(false);
    console.log("Failed:", errorInfo);
    if (!props.isConnect) {
      Swal.fire({
        icon: "warning",
        title: "Please connect your wallet",
      });
    }
  };

  return isTransaction ? (
    <>
      <div className="h-screen w-full bg-white ">
        <Loading />
      </div>
    </>
  ) : (
    <>
      {" "}
      <Form
        name="time_related_controls"
        initialValues={{
          remember: true,
        }}
        layout={"vertical"}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {InputData.map((item, key) => (
          <Form.Item
            label={item.title}
            name={item.placeholder}
            key={key}
            rules={[
              {
                required: true,
                message: `Please input your ${item.placeholder}!`,
              },
            ]}
          >
            <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
              <div className="w-full border-none text-left">
                {item.type === "text" ? (
                  <Input className=" w-full bg-[transparent]  outline-0" />
                ) : (
                  <InputNumber
                    className=" w-full bg-[transparent]  outline-0"
                    min="0"
                    step="0.00001"
                    stringMode
                    id={item.id}
                  />
                )}
              </div>
            </div>
          </Form.Item>
        ))}
        <Form.Item
          name="isStack"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
            <div className="w-full border-none text-left">
              <Checkbox>Do you want to Stake</Checkbox>
            </div>
          </div>
        </Form.Item>
        {SocialMedia.map((item, key) => (
          <Form.Item label={item.title} name={item.placeholder} key={key}>
            <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
              <div className="w-full border-none text-left">
                <Input className=" w-full bg-[transparent]  outline-0" />
              </div>
            </div>
          </Form.Item>
        ))}
        {/* <div className="flex rounded-md p-[8px] border border-[#e8ecf4]  ease-in duration-300  hover:ease-out hover:border-[#060b27]">
       <div className="w-full border-none "> */}
        <Form.Item name="startDate" label="Start Date" {...config}>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            className="w-full hover:outline-none"
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
          />
        </Form.Item>
        {/* </div> */}
        {/* </div> */}
        <Form.Item name="endDate" label="End Date" {...config}>
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            className="w-full hover:outline-none"
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
const InputData = [
  {
    title: "ICO Name",
    placeholder: "Name",
    type: "text",
  },
  {
    title: "ICO Symbol",
    placeholder: "Symbol",
    type: "text",
  },

  {
    title: "Presale Rate",
    placeholder: "Rate",
    type: "number",
  },
  {
    title: "Token Contract Address",
    placeholder: "idoTokenContractAddress",
    type: "text",
  },
  {
    title: "Payout Contract Address",
    placeholder: "payoutTokenContractAddress",
    type: "text",
  },

  {
    title: "Soft Cap",
    placeholder: "Soft_Cap",
    type: "number",
    id: "soft_cap",
  },
  {
    title: "Hard Cap",
    placeholder: "Hard_Cap",
    type: "number",
    id: "hard_cap",
  },
  {
    title: "Min Investment",
    placeholder: "MinInvest",
    type: "number",
    id: "mininvest",
  },
  {
    title: "Max Investment",
    placeholder: "MaxInvest",
    type: "number",
    id: "maxinvest",
  },
  {
    title: "Icon ",
    placeholder: "IconUrl",
    type: "text",
  },
  {
    title: "Description",
    placeholder: "Description",
    type: "text",
  },
];

const SocialMedia = [
  {
    title: "Facebook",
    placeholder: "FacebookUrl",
    type: "text",
  },
  {
    title: "Twitter",
    placeholder: "TwitterUrl",
    type: "text",
  },
  {
    title: "Website",
    placeholder: "WebsiteUrl",
    type: "text",
  },
];
export default connect(mapStateToProps, null)(FormCreatePage);

import { FactotyABI } from "../Config/ABI/FactotyABI";
import { FactotyContract } from "../Config/Contract/Contract";
import { web3_ } from "../Services";
//Get Contract
export const FactoryHelperContract = () => {
  return new web3_.eth.Contract(FactotyABI, FactotyContract);
};

export const FactoryGetAllIntractionDetails = async () => {
  let details = await FactoryHelperContract()
    .methods.getAllIntractionDetails()
    .call();
  console.log(details);
  return details;
};

export const FactoryGetLastIDOStatusEnded = async () => {
  let details = await FactoryHelperContract()
    .methods.getLastIDOStatusEnded()
    .call();
  return details;
};

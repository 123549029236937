import React from "react";
import Web3 from "web3";
import { UpdateAddress, UpdateWalletBalance } from "../Redux/Action";
import { ConnectWallet, WalletDisconnect } from "../Redux/Action/index";
import { store } from "../Redux/store";
import { provider } from "./WalletConnect";
export var web3_ = new Web3(
  "https://ropsten.infura.io/v3/9c48d1f781404552b1a017d597f6bee1"
);
// export var web3_ = new Web3();
// Metamask fucnitonltiy
export const ConnectMetamask = async () => {
  if (window.ethereum) {
    web3_ = new Web3(window.ethereum);
    await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res) => {
        store.getState().ConnectWallet.address = res[0];
        store.getState().ConnectWallet.isConnect = true;
        ConnectWallet(res[0]);
        localStorage.setItem(
          "WalletConnect",
          JSON.stringify({
            isConnect: true,
            address: res[0],
          })
        );
      });
    console.log(store.getState().ConnectWallet.isConnect);
  } else {
    alert("Please connect via Wallet Connect");
  }
  if (window.ethereum) {
    window.ethereum.on("connect", (connect) => {
      console.log(connect);
    });

    window.ethereum.on("accountsChanged", (accounts) => {
      console.log(accounts[0]);
      UpdateAddress(accounts[0]);
      UpdateWalletBalance(accounts[0]);
      store.getState().ConnectWallet.isConnect = true;
      localStorage.setItem(
        "WalletConnect",
        JSON.stringify({
          isConnect: true,
          address: accounts[0],
        })
      );
    });

    // Subscribe to chainId change
    window.ethereum.on("chainChanged", (chainId) => {
      console.log(chainId);
    });

    // Subscribe to session disconnection
    window.ethereum.on("disconnect", (code, reason) => {
      console.log(code, reason);
    });
  }
};

// Wallect Connnect Funcitonltiy
export const ConnectWeb3Wallet = async () => {
  await provider.enable();
  web3_ = new Web3(provider);
  let address = await web3_.eth.getAccounts();
  store.getState().ConnectWallet.address = address[0];
  store.getState().ConnectWallet.isConnect = true;
  localStorage.setItem(
    "WalletConnect",
    JSON.stringify({
      isConnect: true,
      address: address[0],
    })
  );
  provider.on("accountsChanged", async (accounts) => {
    console.log(accounts);
    UpdateAddress(accounts[0]);
    UpdateWalletBalance(accounts[0]);

    localStorage.setItem(
      "WalletConnect",
      JSON.stringify({
        isConnect: true,
        address: accounts[0],
      })
    );
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    console.log(chainId);
  });

  // Subscribe to session disconnection
  provider.on("disconnect", (code, reason) => {
    console.log(code, reason);
  });
};

export const DisconnectWallet = () => {
  localStorage.setItem(
    "WalletConnect",
    JSON.stringify({
      isConnect: false,
      address: "",
    })
  );
  if (!window.ethereum) {
    web3_.currentProvider.disconnect();
  } else {
    WalletDisconnect();
    provider.disconnect();
  }
};

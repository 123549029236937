import React from "react";

function Footer() {
  return (
    <>
      {" "}
      <div class="w-full text-center bg-[#000] text-[#fff] text-sm py-[48px] border-t border-[#d9b865]">
        <div>© 2022 Project Psalms</div>
      </div>
    </>
  );
}

export default Footer;
